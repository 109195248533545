import React, { useEffect, useRef, useState } from "react";
import Select from "react-select";
import { useField } from "@unform/core";
import { Label, LabelContainer } from "./Label";
import { ErrorMessage } from "./ErrorMessage";

const customStyles = {
    control: (provided) => ({
        ...provided,
        height: 44,
        /* backgroundColor: state.isFocused ? '#fff' : 'transparent', */
    }),
    option: (provided) => ({ ...provided, height: 44 })
};

export default function ReactSelect({
    name,
    label,
    options,
    subType,
    onChange = null,
    isMandatory,
    ...rest
}) {
    const selectRef = useRef(null);
    const { fieldName, defaultValue, registerField, error } = useField(name);
    const [selectedOption, setSelectedOption] = useState(null);

    useEffect(() => {
        registerField({
            name: fieldName,
            ref: selectRef.current,
            path: "value",
            getValue: () => selectedOption ? selectedOption.value : "", // Define o valor no Unform
        });
    }, [fieldName, registerField, selectedOption]);

    useEffect(() => {
        // Seta o valor inicial
        const initialOption = reactSelectOptions.find(option => option.value === defaultValue);
        setSelectedOption(initialOption);
    }, [defaultValue, options]);

    const getDefaultValue = () => {
        const lowerCaseDefault =
            typeof defaultValue === "string"
                ? defaultValue.toLowerCase()
                : defaultValue;

        const option =
            options?.find((option) => {
                const lowerCaseName =
                    typeof option.name === "string"
                        ? option.name.toLowerCase()
                        : option.name;
                return lowerCaseName.includes(lowerCaseDefault);
            }) || {};

        if (option)
            return rest.returnValue
                ? option.name
                : option.id
                    ? option.id
                    : option.cod;
    };

    const handleChange = (selectedOption) => {
        setSelectedOption(selectedOption);
        if (onChange) {
            onChange({
                target: {
                    name: fieldName,
                    value: selectedOption ? selectedOption.value : "",
                },
            });
        }
    };

    const reactSelectOptions = options
        ? options.map((option) => ({
            label: option.name,
            value: rest.returnValue
                ? option.name
                : option.id
                    ? option.id
                    : option.cod,
        }))
        : [];

    return (
        <div style={{ display: rest.hide ? "none" : "block" }}>
            <LabelContainer>
                <Label htmlFor={fieldName}>{label}</Label>
                {isMandatory && <span style={{ color: "red" }}>*</span>}
            </LabelContainer>

            <Select
                closeMenuOnSelect={true}
                placeholder="Pesquise pela entidade..."
                id={fieldName}
                ref={selectRef}
                value={selectedOption}
                isClearable={true}
                options={reactSelectOptions}
                onChange={handleChange}
                styles={customStyles}
                isDisabled={rest.disabled}
                {...rest}
            />

            {error && <ErrorMessage>{error}</ErrorMessage>}
        </div>
    );
};
